import { parseDomain, ParseResultType } from 'parse-domain';

export default class LoginManager {
  static isUpdating = false;
  static domain = this.getDomain();
  static adminKeyIdentifier = 'admin';
  static authAlgorithm = 'RS256';

  static getDomain(): string {
    if (typeof process === 'object') {
      if (process.env.DOMAIN) return process.env.DOMAIN;

      throw new Error('No domain found (ENV)');
    }

    if (typeof window !== 'undefined') {
      const parseResult = parseDomain(window.location.hostname);

      if (parseResult.type == ParseResultType.Listed) return parseResult.domain + '.' + parseResult.topLevelDomains.join('.');
      if (parseResult.type == ParseResultType.Reserved || parseResult.type == ParseResultType.Ip) return parseResult.hostname;

      throw new Error('No domain found (WINDOW)');
    }

    throw new Error('No domain found');
  }

  static async validateToken(): Promise<void> {
    if (this.isUpdating) {
      while (this.isUpdating) {
        await this.sleep(100);
      }

      return;
    }

    try {
      if (this.hasCookie('token')) {
        const token = this.parseJwt(this.getCookie('token')!);

        if (!this.tokenExpired(token)) return;

        const isSuccess = await this.refreshToken();

        if (!isSuccess) window.location.href = 'https://login.' + this.domain + '?redirect=' + encodeURIComponent(window.location.href);
      } else if (this.hasCookie('refreshToken')) {
        const isSuccess = await this.refreshToken();

        if (!isSuccess) window.location.href = 'https://login.' + this.domain + '?redirect=' + encodeURIComponent(window.location.href);
      } else {
        window.location.href = 'https://login.' + this.domain + '?redirect=' + encodeURIComponent(window.location.href);
      }
    } catch (e) {
      console.log(e);
      window.location.href = 'https://login.' + this.domain + '?redirect=' + encodeURIComponent(window.location.href);
    }
  }

  static async updateToken(): Promise<void> {
    while (true) {
      if (this.isUpdating) {
        await this.sleep(500);
        continue;
      }

      if (this.hasCookie('token')) {
        const token = this.parseJwt(this.getCookie('token')!);

        await this.sleep(new Date(token.exp * 1000).getTime() - 60000 - new Date().getTime());
      } else {
        await this.sleep(1000);
      }

      this.validateToken();
    }
  }

  static refreshToken(): Promise<boolean> {
    return new Promise((resolve, _) => {
      this.isUpdating = true;
      const refreshToken = this.getCookie('refreshToken');
      const token = this.getCookie('token');
      const parseResult = parseDomain(window.location.hostname);
      let domain = '';

      if (parseResult.type == ParseResultType.Listed) domain = parseResult.domain + '.' + parseResult.topLevelDomains.join('.');
      else if (parseResult.type == ParseResultType.Reserved || parseResult.type == ParseResultType.Ip) domain = parseResult.hostname;
      else {
        this.isUpdating = false;
        return resolve(false);
      }

      const req = new XMLHttpRequest();
      req.responseType = 'json';
      req.withCredentials = domain == this.domain;

      req.open('POST', 'https://api.login.' + domain + '/refresh' + (domain == this.domain ? '' : '?json=true'), true);
      req.setRequestHeader('Content-Type', 'application/json');
      req.setRequestHeader('Accept', 'application/json');
      req.setRequestHeader('Authorization', 'Bearer ' + refreshToken);
      req.onload = () => {
        if (200 == req.status && 200 == req.response.statusCode) {
          if (req.response.data != undefined) {
            document.cookie = 'token=' + req.response.data.access_token + ';path=/;Secure;SameSite=Strict;domain=.' + domain + ';max-age=' + this.expiresIn(this.parseJwt(req.response.data.access_token)) / 1000;
            document.cookie = 'refreshToken=' + req.response.data.refresh_token + ';path=/;Secure;SameSite=Lax;domain=.' + domain + ';max-age=' + this.expiresIn(this.parseJwt(req.response.data.refresh_token)) / 1000;
          }

          if (!this.hasCookie('refreshToken') || !this.hasCookie('token')) resolve(false);

          this.isUpdating = false;

          var event = new CustomEvent('refresh', { cancelable: false });
          window.dispatchEvent(event);

          resolve(true);
        } else {
          if (this.hasCookie('token') && this.getCookie('token') !== token) {
            if (this.tokenExpired(this.parseJwt(this.getCookie('token')!))) {
              this.deleteCookie('token', '/', '.' + domain);
              this.deleteCookie('refreshToken', '/', '.' + domain);
              resolve(false);
              return;
            }
          }

          this.isUpdating = false;
          resolve(true);
        }
      };
      req.send();
    });
  }

  static tokenExpired(jwt: any): boolean {
    return new Date(jwt.exp * 1000).getTime() - 60000 < new Date().getTime();
  }

  static expiresIn(jwt: any): number {
    return new Date(jwt.exp * 1000).getTime() - new Date().getTime() - 60000;
  }

  static hasCookie(name: string): boolean {
    return document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')) != null ? true : false;
  }

  static getCookie(name: string): string | undefined {
    const t = `; ${document.cookie}`;
    const o = t.split(`; ${name}=`);

    if (o.length === 2) return o.pop()!.split(';').shift();
  }

  static deleteCookie(name: string, t: string, o: string): void {
    this.getCookie(name) && (document.cookie = name + '=' + (t ? ';path=' + t : '') + (o ? ';domain=' + o : '') + ';expires=Thu, 01 Jan 1970 00:00:01 GMT');
  }

  static parseJwt(token: string): any {
    const t = token.split('.')[1];
    const o = t.replace(/-/g, '+').replace(/_/g, '/');
    const n = decodeURIComponent(
      atob(o)
        .split('')
        .map((e) => {
          return '%' + ('00' + e.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(n);
  }

  static getUsername(): string | undefined {
    const cookie = this.getCookie('token');

    if (cookie == undefined) return undefined;

    const e = this.parseJwt(cookie);
    return e.username;
  }

  static getAvatar(): string | undefined {
    const cookie = this.getCookie('token');

    if (cookie == undefined) return undefined;

    const e = this.parseJwt(cookie);
    return e.avatar;
  }

  static getUser(): any | undefined {
    const cookie = this.getCookie('token');

    if (cookie == undefined) return undefined;

    const e = this.parseJwt(cookie);

    return {
      username: e.username,
      avatar: e.avatar,
      email: e.email,
      id: e.sub,
      lang: e.lang,
      scopes: e.scope,
    };
  }

  static async logout(): Promise<void> {
    return new Promise<void>((resolve, _) => {
      const refreshToken = this.getCookie('refreshToken');
      if (refreshToken == undefined) return;

      const req = new XMLHttpRequest();
      req.responseType = 'json';
      req.open('DELETE', 'https://api.login.' + this.domain + '/revoke', true);
      req.setRequestHeader('Authorization', `Bearer ${refreshToken}`);
      req.onload = () => {
        if (req.status == 200 && req.response.statusCode == 200) {
          const parseResult = parseDomain(window.location.hostname);

          if (parseResult.type == ParseResultType.Listed) {
            const domain = parseResult.domain + '.' + parseResult.topLevelDomains.join('.');

            this.deleteCookie('token', '/', '.' + domain);
            this.deleteCookie('refreshToken', '/', '.' + domain);
          } else {
            throw new Error('Invalid domain');
          }

          //trigger logout event
          var event = new CustomEvent('logout', { cancelable: true });
          window.dispatchEvent(event);

          resolve();
        }
      };
      req.send();
    });
  }

  static hasScope(category: string, scope: number | null, scopes: string): boolean | undefined {
    const scopesArray = scopes.split(' ');

    for (let i = 0; i < scopesArray.length; i++) {
      const scopeParts = scopesArray[i].split(':');

      if (scopeParts[0] == category) {
        if (scope == null) return true;
        if ((parseInt(scopeParts[1]) & scope) === scope) return true;
      }
    }

    return false;
  }

  static addKeyIdentifiers(key: string): string {
    return "-----BEGIN PUBLIC KEY-----\n" + key + "\n-----END PUBLIC KEY-----";
  }

  static async isLoggedIn(): Promise<boolean> {
    if (this.hasCookie('token') && !this.tokenExpired(this.getCookie('token'))) return true;

    if (this.hasCookie('refreshToken') && !this.tokenExpired(this.getCookie('refreshToken'))) return await this.refreshToken();

    return false;
  }

  private static sleep(e: number): Promise<void> {
    return new Promise((t) => setTimeout(t, e));
  }
}
